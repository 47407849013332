import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import { Select, COMPONENTS } from 'sw-ui';

import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';
import { Rates } from './components/Rates';
import SelectCompany from '../selectCompany';

import { momentObject } from '../../../../bi/utils/formatDate';
import { formatDate } from '../../../../utils/date';

import { TRIPSLABELS, FIELDS, PRICELABELS, FUNDSFIELDS } from '../../../../bi/constants/trips';
import { SERVICETYPE, SERVICETYPERU } from '../../../../bi/constants/serviceType';
import { PROVIDERS_INSURANCE } from '../../../../bi/constants/insurance';
import {
  DEFAULTDATE,
  DEFAULTTIME,
  LOCALERU,
  DATE_WITHOUT_TIME_ZONE,
} from '../../../../constants/time';

import styles from '../../styles/form.module.scss';

const {
  SELECT: { THEME: { BORDER } },
} = COMPONENTS;

const LABELS = {
  SELECTED_ITEMS: 'Застрахованные услуги:',
  ADD_BUTTON: 'Сохранить страховку',
  CHECKIN: 'Дата начала услуги',
  CHECKOUT: 'Дата окончания услуги',
};

const InsuranceEdit = ({
  item,
  companies,
  tripService,
  guidService,
  onConfirm,
}) => {
  const {
    insurance: {
      rates,
      items,
      description,
      companyId,
      provider,
      selectedRate,
      price: { Base, Commission },
      validation,
    },
  } = tripService.get();

  const getIsServicesWithName = ServiceType => ServiceType === SERVICETYPE.HOTEL ||
    ServiceType === SERVICETYPE.CUSTOM;
  const getIsServicesWithCheckout = ServiceType => ServiceType === SERVICETYPE.HOTEL ||
    ServiceType === SERVICETYPE.AIR ||
    ServiceType === SERVICETYPE.TRAIN;

  useEffect(() => {
    tripService.setEditedInsurance(item);
    tripService.recalculateRates();

    return () => tripService.resetInsurance();
  }, []);

  const validateFields = (field, value, idx) => {
    tripService.insuranceTripFieldValidation(field, value, idx);
  };

  const validateBeforeSave = () => {
    tripService.insuranceTripFieldValidation(FIELDS.DESCRIPTION, description);
    tripService.insuranceTripFieldValidation(FIELDS.COMPANYID, companyId);
    tripService.insuranceTripFieldValidation(FIELDS.BASEPRICE, Base);
    tripService.insuranceTripFieldValidation(FUNDSFIELDS.COMMISSION, Commission);

    items.forEach(({ Data, Amount, ServiceType }, idx) => {
      const data = JSON.parse(Data);
      const { Name, Direction, CheckinDate, CheckoutDate, AirlineRoutesDates } = data;

      if (getIsServicesWithName(ServiceType)) {
        tripService.insuranceTripFieldValidation(FIELDS.NAME, Name, idx);
      } else {
        tripService.insuranceTripFieldValidation(FIELDS.DIRECTION, Direction, idx);
      }

      if (getIsServicesWithCheckout(ServiceType)) {
        const checkout = ServiceType === SERVICETYPE.AIR ?
          AirlineRoutesDates[AirlineRoutesDates.length - 1].CheckoutDate :
          CheckoutDate;

        tripService.insuranceTripFieldValidation(FIELDS.CHECKOUT_DATE, momentObject(checkout), idx);
      }

      const checkin = ServiceType === SERVICETYPE.AIR ?
        AirlineRoutesDates[0].CheckinDate :
        CheckinDate;

      tripService.insuranceTripFieldValidation(FIELDS.CHECKIN_DATE, momentObject(checkin), idx);
      tripService.insuranceTripFieldValidation(FIELDS.AMOUNT, Amount, idx);
    });
  };

  const handleChangeAmount = async (value, idx) => {
    tripService.changeEditedServiceInsurance(FIELDS.AMOUNT, value, idx);

    await tripService.recalculateRates();
  };

  const handleConfirm = async () => {
    validateBeforeSave();

    const Guid = guidService.generate();

    const data = await tripService.prepareDataForSave(Guid, true);

    if (data) {
      onConfirm(data);
    }
  };

  const handleChangeDate = (field, value, routesDates, idx, serviceType) => {
    const newValue = formatDate(value, DATE_WITHOUT_TIME_ZONE);

    if (serviceType === SERVICETYPE.AIR) {
      const newRoutesDates = routesDates.map(({ CheckinDate, CheckoutDate }, index) => {
        if (index === 0 && field === FIELDS.CHECKIN_DATE) {
          return {
            CheckinDate: newValue,
            CheckoutDate,
          };
        }

        if (index === routesDates.length - 1 && FIELDS.CHECKOUT_DATE) {
          return {
            CheckinDate,
            CheckoutDate: newValue,
          };
        }

        return { CheckinDate, CheckoutDate };
      });

      return tripService.changeDataServiceInsurance(FIELDS.AIRLINE_ROUTES_DATES, newRoutesDates, idx);
    }

    return tripService.changeDataServiceInsurance(field, newValue, idx);
  };

  const renderItemName = (name, direction, serviceType, idx) => {
    if (getIsServicesWithName(serviceType)) {
      const isValid = validation.Items.length && validation.Items[idx].Name;

      return (
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FIELDS.NAME }
            value={ name }
            label={ TRIPSLABELS.NAME_SERVICE }
            onChange={ (e, field, value) => tripService.changeDataServiceInsurance(field, value, idx) }
            onBlur={ (e, field) => validateFields(field, name, idx) }
            valid={ isValid }
          />
        </div>
      );
    }

    const isValid = validation.Items.length && validation.Items[idx].Direction;

    return (
      <div className={ styles['col-1-5'] }>
        <Input
          field={ FIELDS.DIRECTION }
          value={ direction }
          label={ TRIPSLABELS.DIRECTION_SERVICE }
          onChange={ (e, field, value) => tripService.changeDataServiceInsurance(field, value, idx) }
          onBlur={ (e, field) => validateFields(field, direction, idx) }
          valid={ isValid }
        />
      </div>
    );
  };

  const renderItemCheckout = (airlineRoutesDates, checkoutDate, serviceType, idx) => {
    if (!getIsServicesWithCheckout(serviceType)) return null;

    const checkout = serviceType === SERVICETYPE.AIR ?
      momentObject(airlineRoutesDates[airlineRoutesDates.length - 1].CheckoutDate) :
      momentObject(checkoutDate);
    const validCheckoutHtml = validation.Items.length &&
      validation.Items[idx].CheckoutDate &&
      <span className='error-msg'>{ validation.Items[idx].CheckoutDate }</span>;
    const validationStyle = validation.Items.length && validation.Items[idx].CheckoutDate ? 'no-valid' : '';

    return (
      <div className={ styles['col-1-5'] }>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat={ DEFAULTTIME }
          locale={ LOCALERU }
          value={ checkout }
          onChange={ value => handleChangeDate(FIELDS.CHECKOUT_DATE, value, airlineRoutesDates, idx, serviceType) }
          onBlur={ () => validateFields(FIELDS.CHECKOUT_DATE, checkout, idx) }
          className={ `componentHook ${validationStyle}` }
        />
        <label>{ LABELS.CHECKOUT }</label>
        { validCheckoutHtml }
      </div>
    );
  };

  const renderItems = () => items.map((service, idx) => {
    const { Data, Amount, ServiceType } = service;

    const data = JSON.parse(Data);
    const { Name, Direction, CheckinDate, CheckoutDate, AirlineRoutesDates } = data;

    const checkin = ServiceType === SERVICETYPE.AIR ?
      momentObject(AirlineRoutesDates[0].CheckinDate) :
      momentObject(CheckinDate);

    const validCheckinHtml = validation.Items.length &&
      validation.Items[idx].CheckinDate &&
      <span className='error-msg'>{ validation.Items[idx].CheckinDate }</span>;
    const validCheckinStyle = validation.Items.length && validation.Items[idx].CheckinDate ?
      'no-valid' :
      '';
    const validAmount = validation.Items.length && validation.Items[idx].Amount;

    return (
      <div className={ styles.row } key={ idx }>
        <h4>{ SERVICETYPERU[ServiceType] }</h4>
        <div className={ styles.row }>
          { renderItemName(Name, Direction, ServiceType, idx) }
          <div className={ styles['col-1-5'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              locale={ LOCALERU }
              value={ checkin }
              onChange={ value => handleChangeDate(FIELDS.CHECKIN_DATE, value, AirlineRoutesDates, idx, ServiceType) }
              onBlur={ () => validateFields(FIELDS.CHECKIN_DATE, checkin, idx) }
              className={ `componentHook ${validCheckinStyle}` }
            />
            <label>{ LABELS.CHECKIN }</label>
            { validCheckinHtml }
          </div>
          { renderItemCheckout(AirlineRoutesDates, CheckoutDate, ServiceType, idx) }
          <div className={ styles['col-1-5'] }>
            <Input
              field={ FIELDS.AMOUNT }
              type='number'
              value={ Amount }
              label={ TRIPSLABELS.AMOUNT }
              onChange={ (e, field, value) => handleChangeAmount(value, idx) }
              onBlur={ (e, field) => validateFields(field, Amount, idx) }
              valid={ validAmount }
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={ styles.wrap }>
      <div className={ `${styles.row} ${styles.panel}` }>
        <Input
          field={ FIELDS.DESCRIPTION }
          value={ description }
          label={ TRIPSLABELS.DESCRIPTION }
          onChange={ (e, field, value) => tripService.setDescriptionInsurance(value) }
          onBlur={ (e, field) => validateFields(field, description) }
          valid={ validation.Description }
        />
      </div>
      <div className={ styles.row }>
        <SelectCompany
          currentCompanyId={ companyId }
          companies={ companies }
          onSelect={ tripService.selectCompanyId }
          validationCompany={ validation.CompanyId }
        />
      </div>
      <div className={ styles.row }>
        <Select
          label={ TRIPSLABELS.NAME_PROVIDER }
          items={ PROVIDERS_INSURANCE }
          value={ provider }
          theme={ BORDER }
          onChange={ ({ value }) => tripService.setProviderInsurance(value) }
        />
      </div>
      <div className={ styles.row }>
        <h4>{ LABELS.SELECTED_ITEMS }</h4>
        { renderItems() }
      </div>
      <Rates
        rates={ rates }
        selectedRate={ selectedRate }
        onSelectRate={ tripService.selectRateInsurance }
        disabled
      />
      <div className={ styles.row }>
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FIELDS.BASEPRICE }
            value={ Base }
            type='number'
            label={ PRICELABELS.BASEPRICE }
            onChange={ (e, field, value) => tripService.changePriceInsurance(field, value) }
            onBlur={ (e, field) => validateFields(field, Base) }
            valid={ validation.Base }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FUNDSFIELDS.COMMISSION }
            value={ Commission }
            type='number'
            label={ PRICELABELS.COMMISSIONSMARTWAY }
            onChange={ (e, field, value) => tripService.changePriceInsurance(field, value) }
            onBlur={ (e, field) => validateFields(field, Commission) }
            valid={ validation.Commission }
          />
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <FlatButton
            label={ LABELS.ADD_BUTTON }
            onClick={ handleConfirm }
          />
        </div>
      </div>
    </div>
  );
};

InsuranceEdit.propTypes = {
  item: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  tripService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export { InsuranceEdit };
